import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "public-profile-not-found" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotFound = _resolveComponent("NotFound")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NotFound, {
      iconSrc: _ctx.defaultAvatarSrc,
      title: _ctx.t('Profile not found'),
      "sub-title": 
        _ctx.t('Looking for someone?\nWe also could not find that profile.')
      
    }, null, 8, ["iconSrc", "title", "sub-title"])
  ]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "public-profile-details" }
const _hoisted_2 = { class: "public-profile-details__header-action" }
const _hoisted_3 = {
  key: 1,
  class: "public-profile-details__details"
}
const _hoisted_4 = { class: "public-profile-details__details-name" }
const _hoisted_5 = {
  key: 0,
  class: "flex px-4"
}
const _hoisted_6 = {
  key: 0,
  class: "text-center mt-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopHeaderLogout = _resolveComponent("TopHeaderLogout")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_FullPageCardLayout = _resolveComponent("FullPageCardLayout")!
  const _component_CustomListContacts = _resolveComponent("CustomListContacts")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FullPageCardLayout, null, {
      "header-right": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.isAuthenticated)
            ? (_openBlock(), _createBlock(_component_TopHeaderLogout, {
                key: 0,
                class: "public-profile-details__header-action-logout",
                "always-show": _ctx.isAuthenticated
              }, null, 8, ["always-show"]))
            : (!_ctx.isAuthenticated && !_ctx.publicProfileDetailsLoading)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  class: "public-profile-details__header-action-create-account",
                  onClick: _ctx.createAccount
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Create account")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.publicProfileDetailsLoading)
          ? (_openBlock(), _createBlock(_component_a_skeleton, {
              key: 0,
              active: "",
              avatar: { size: 64 },
              paragraph: { rows: 1 }
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_a_image, {
                class: "public-profile-details__details-avatar",
                src: _ctx.publicProfileDetails?.avatar || _ctx.defaultAvatarSrc,
                fallback: _ctx.defaultAvatarSrc
              }, null, 8, ["src", "fallback"]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_typography_title, { level: 4 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.publicProfileDetails?.name || _ctx.t("Avatarada user")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_typography_paragraph, { class: "lighter" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.publicProfileDetails?.name
                ? _ctx.t("Public Avatarada profile")
                : _ctx.t("Anonymous")), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
      ]),
      _: 1
    }),
    _createVNode(_component_CommonCardLayout, { class: "mt-8 public-profile-details__contacts" }, {
      title: _withCtx(() => [
        (_ctx.publicProfileDetailsLoading)
          ? (_openBlock(), _createBlock(_component_a_skeleton, {
              key: 0,
              active: "",
              paragraph: false,
              title: { width: '30%' }
            }))
          : (_openBlock(), _createBlock(_component_a_typography_title, {
              key: 1,
              level: 3,
              class: "whitespace-normal m-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Contacts")), 1)
              ]),
              _: 1
            }))
      ]),
      default: _withCtx(() => [
        (_ctx.publicProfileDetailsLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_a_skeleton, {
                active: "",
                paragraph: false,
                title: { width: '80%' }
              }),
              _createVNode(_component_a_skeleton, {
                active: "",
                paragraph: false,
                title: { width: '80%' }
              }),
              _createVNode(_component_a_skeleton, {
                active: "",
                paragraph: false,
                title: { width: '80%' }
              })
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.isEmpty(_ctx.publicProfileDetails?.channels))
                ? (_openBlock(), _createBlock(_component_a_typography_title, {
                    key: 0,
                    level: 4,
                    class: "m-8 font-normal lighter text-center"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("There are no public contact details ¯\_(ツ)_/¯")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_CustomListContacts, {
                "channel-list": _ctx.channelList,
                "col-props": {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24,
            xl: 24,
            xxl: 24,
          }
              }, null, 8, ["channel-list"])
            ], 64))
      ]),
      _: 1
    }),
    (!_ctx.publicProfileDetailsLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkList, (link) => {
            return (_openBlock(), _createBlock(_component_a_button, {
              key: link.key,
              type: "link",
              class: "px-4",
              onClick: ($event: any) => (_ctx.openInNewTab(link.value))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(link.key), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
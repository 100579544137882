import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    class: "custom-list-contacts",
    style: {"row-gap":"24px"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channelList, (channel) => {
        return (_openBlock(), _createBlock(_component_a_col, _mergeProps({
          key: channel.value,
          xs: 24,
          sm: 24,
          md: 12,
          lg: 8
        }, _ctx.colProps), {
          default: _withCtx(() => [
            _createVNode(_component_a_space, {
              size: 6,
              class: "flex-wrap"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PlatformIcon, {
                  class: "channel-item__icon",
                  name: _ctx.get(channel, 'provider', channel.__typename)
                }, null, 8, ["name"]),
                _createVNode(_component_a_typography_paragraph, { class: "m-0" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(channel.value), 1)
                  ]),
                  _: 2
                }, 1024),
                (channel.isPrimary)
                  ? (_openBlock(), _createBlock(_component_a_alert, {
                      key: 0,
                      message: _ctx.t('Main'),
                      type: "success",
                      banner: "",
                      "show-icon": false
                    }, null, 8, ["message"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1040))
      }), 128))
    ]),
    _: 1
  }))
}

import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import isEmpty from "lodash/isEmpty";
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { usePublicProfile } from "@/shared/composables/Profile/usePublicProfile";
import defaultAvatarSrc from "@/assets/profile/default-avatar.svg";
import CustomListContacts from "@/shared/components/CustomList/CustomListContacts.vue";
import { openInNewTab } from "@/shared/utils/browser";
import TopHeaderLogout from "@/web/views/Register/TopHeaderLogout.vue";
import { useAccount } from "@/shared/composables/Profile/useAccount";
import routeNames from "@/shared/router/routeNames";

const linkMap = {
  JSON: "publicDataJson",
  XML: "publicDataXml",
  PHP: "publicDataPhp",
  VCF: "publicDataVcf",
  QR: "publicDataQr",
};

export default defineComponent({
  components: {
    FullPageCardLayout,
    CommonCardLayout,
    CustomListContacts,
    TopHeaderLogout,
  },
  props: {
    stakingKeyHash: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const { isAuthenticated } = useAccount();
    const stakingKeyHash = computed(
      () => router.currentRoute?.value?.params?.staking_key_hash
    );
    const { publicProfileDetails, publicProfileDetailsLoading } =
      usePublicProfile({ stakingKeyHash });

    const channelList = computed(() => {
      if (isEmpty(publicProfileDetails.value?.channels)) {
        return [];
      }

      return publicProfileDetails.value?.channels?.map((channel) => ({
        ...channel,
        __typename: channel?.__typename.replace("Public", ""),
      }));
    });

    const linkList = computed(() => {
      return Object.keys(linkMap)
        .map((key) => ({
          key,
          value: publicProfileDetails?.value?.[linkMap[key]],
        }))
        .filter((item) => !!item.value); // do not return item without value
    });

    const createAccount = () => {
      router.push({ name: routeNames.register });
    };

    return {
      t,
      isAuthenticated,
      defaultAvatarSrc,
      publicProfileDetails,
      publicProfileDetailsLoading,
      channelList,
      linkList,
      createAccount,
    };
  },
  methods: { isEmpty, openInNewTab },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "public-profile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PublicProfileNotFound = _resolveComponent("PublicProfileNotFound")!
  const _component_PublicProfileDetails = _resolveComponent("PublicProfileDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.publicProfileNotFound)
      ? (_openBlock(), _createBlock(_component_PublicProfileNotFound, { key: 0 }))
      : (_openBlock(), _createBlock(_component_PublicProfileDetails, {
          key: 1,
          "staking-key-hash": _ctx.stakingKeyHash
        }, null, 8, ["staking-key-hash"]))
  ]))
}
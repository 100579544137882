
import { computed, defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { usePublicProfile } from "@/shared/composables/Profile/usePublicProfile";
import PublicProfileDetails from "@/web/views/PublicProfile/PublicProfileDetails.vue";
import PublicProfileNotFound from "@/web/views/PublicProfile/PublicProfileNotFound.vue";
import { useAccount } from "@/shared/composables/Profile/useAccount";
import { registrationSimpleStore } from "@/web/store/registrationStore";

export default defineComponent({
  components: { PublicProfileDetails, PublicProfileNotFound },
  props: {},
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const { isAuthenticated, selectedUserStakingKeyHash } = useAccount();
    const stakingKeyHash = computed(
      () => router.currentRoute?.value?.params?.staking_key_hash
    );
    const { publicProfileNotFound } = usePublicProfile({ stakingKeyHash });

    onMounted(() => {
      /**
       * Register top header were re-used
       * On mount check if there's an authenticated user
       * and save staking key hash on register staking ID
       */
      if (isAuthenticated.value && selectedUserStakingKeyHash.value) {
        registrationSimpleStore.stakingId = selectedUserStakingKeyHash.value;
      }
    });

    return {
      t,
      isAuthenticated,
      publicProfileNotFound,
      stakingKeyHash,
    };
  },
});


import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import get from "lodash/get";
import { CommunicationChannels_communicationChannels_CommunicationChannelResults_results } from "@/api/communicationChannels/__generated__/CommunicationChannels";
import PlatformIcon from "@/shared/components/Icons/PlatformIcon.vue";
import { ColProps } from "ant-design-vue/lib/grid/Col";

export type CustomListContactItem =
  Partial<CommunicationChannels_communicationChannels_CommunicationChannelResults_results> & {
    value: string;
    __typename: string;
    provider: string;
    isPrimary: boolean;
  };

export default defineComponent({
  components: { PlatformIcon },
  props: {
    channelList: {
      type: Array as PropType<CustomListContactItem[]>,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    colProps: {
      type: Object as PropType<ColProps>,
    },
  },
  emits: ["add-contacts"],
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  methods: { get },
});

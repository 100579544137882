import { computed } from "vue";
import { publicProfileGql } from "@/api/profile/publicProfile";
import { PublicProfile_publicProfile_PublicUser } from "@/api/profile/__generated__/PublicProfile";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";
import { apiErrorCodes } from "@/shared/utils/constants";
import some from "lodash/some";

export const usePublicProfile = ({ stakingKeyHash }) => {
  const { result, loading: publicProfileDetailsLoading } = useQuery(
    publicProfileGql,
    { input: { stakingKeyHash } },
    () => ({
      enabled: !!stakingKeyHash,
    })
  );

  const parsedResponse = computed(() => {
    return parseGqlResponse<PublicProfile_publicProfile_PublicUser>(
      "PublicUser",
      result.value,
      apiErrorCodes.INVALID_INPUT
    );
  });

  const publicProfileDetails = computed(() => parsedResponse.value.data);

  const publicProfileNotFound = computed(() => {
    return some(parsedResponse.value?.error?.errors, [
      "code",
      apiErrorCodes.INVALID_INPUT,
    ]);
  });

  return {
    publicProfileDetailsLoading,
    publicProfileDetails,
    publicProfileNotFound,
  };
};

import gql from "graphql-tag";

export const publicProfileGql = gql`
  query PublicProfile($input: PublicProfileInput!) {
    publicProfile(input: $input) {
      ... on PublicUser {
        wallet {
          stakingKeyHash
        }
        name
        avatar
        publicDataJson
        publicDataXml
        publicDataPhp
        publicDataQr
        channels {
          ... on PublicDomainChannel {
            value
          }
          ... on PublicEmailChannel {
            value
          }
          ... on PublicSocialChannel {
            value
            provider
          }
          ... on PublicMobileChannel {
            value
          }
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
